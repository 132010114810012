import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { EventListing, Team } from '@wbk/api';
import { useAnalytics } from '@wbk/analytics';
import { Button } from '@wbk/ui';
import { formatDate } from '@wbk/utils';
import { DEFAULT_FLAG } from '@/src/constants/defaultFlag';
import ImageWithFallback from '../image/ImageWithFallback';

type Props = {
  event: EventListing & {
    home_team?: Pick<Team, '_id' | 'name' | 'logo'>;
    away_team?: Pick<Team, '_id' | 'name' | 'logo'>;
  };
} & React.HTMLAttributes<HTMLElement>;

const MatchListItem = ({ event, ...props }: Props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { generateLeadEvent } = useAnalytics();

  return (
    <Button
      as={Link}
      theme='secondary'
      queueProtected
      href={`/${locale}/event/${event.slug}`}
      onClick={() => {
        generateLeadEvent({
          event_start_price: event.price,
          currency: event.currency || 'SAR',
          title: event.title,
          slug: event.slug,
        });
      }}
      className='flex w-full grow flex-col items-center gap-6 p-4 md:flex-row'
      {...props}
    >
      <div className='flex w-full grow justify-center gap-4 md:items-center'>
        <div className='hidden w-20 truncate rounded-md bg-white/10 p-2 text-center leading-none md:block'>
          {event.tbc ? (
            <p className='py-4'>TBC</p>
          ) : (
            <>
              <p className='text-sm'>
                {formatDate(event.start_date_time, locale, { month: 'long' })}
              </p>
              <p className='-my-1 text-3xl font-bold'>
                {formatDate(event.start_date_time, locale, { day: '2-digit' })}
              </p>
              <p className='text-sm'>
                {formatDate(event.start_date_time, locale, { year: 'numeric' })}
              </p>
            </>
          )}
        </div>

        <div className='flex w-full flex-col items-center gap-6 md:flex-row'>
          <div className='flex items-center gap-2'>
            <figure className='relative h-20 w-20 shrink-0 md:h-16 md:w-16 lg:h-20 lg:w-20'>
              <ImageWithFallback
                src={event?.home_team?.logo}
                className='object-cover'
                fill
                sizes='80px'
                fallbackSrc={DEFAULT_FLAG}
                alt={event?.home_team?.name || ''}
              />
            </figure>
            <p className='hidden text-sm text-gray-200 md:block'>VS</p>
            <div className='space-y-1 text-center md:hidden'>
              <p className='rounded-md bg-white/5 px-2 py-1'>{`${formatDate(event.start_date_time, locale, { day: '2-digit' })} ${formatDate(event.start_date_time, locale, { month: 'long' })} ${formatDate(event.start_date_time, locale, { year: 'numeric' })}`}</p>
              <p className='font-bold'>
                {formatDate(event.start_date_time, locale, { timeStyle: 'short' })}
              </p>
            </div>

            <figure className='relative h-20 w-20 shrink-0 md:h-16 md:w-16 lg:h-20 lg:w-20'>
              <ImageWithFallback
                src={event?.away_team?.logo}
                className='object-cover'
                fill
                sizes='80px'
                fallbackSrc={DEFAULT_FLAG}
                alt={event?.away_team?.name || ''}
              />
            </figure>
          </div>

          <div className='space-y-1 text-start'>
            <p className='text-2xl md:text-xl lg:text-2xl'>
              <strong>{event?.home_team?.name || 'N/A'}</strong>
              <span className='text-xl'>{' VS '}</span>
              <strong>{event?.away_team?.name || 'N/A'}</strong>
            </p>
            {!event.tbc && (
              <p className='hidden font-bold md:block'>
                {formatDate(event.start_date_time, locale, { timeStyle: 'short' })}
              </p>
            )}
          </div>
        </div>
      </div>
      <Button
        className='w-full whitespace-nowrap text-xl font-bold md:max-w-[140px] md:!py-1 md:text-base'
        arrow
        tabIndex={-1}
      >
        {t('event:buy_tickets')}
      </Button>
    </Button>
  );
};

export default MatchListItem;
