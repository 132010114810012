import Image, { ImageProps } from 'next/image';

type Props = Omit<ImageProps, 'src'> &
  Omit<
    JSX.IntrinsicElements['img'],
    'src' | 'srcSet' | 'ref' | 'alt' | 'width' | 'height' | 'loading'
  > & {
    // Since there is a fallback, src is not required
    src?: ImageProps['src'];
    fallbackSrc?: string;
  };

const ImageWithFallback = ({ fallbackSrc, ...props }: Props) => {
  return (
    <Image
      {...props}
      src={props.src || fallbackSrc || '/images/default-poster.png'}
      alt={props.alt}
      onError={(e) => {
        e.currentTarget.srcset = '';
        e.currentTarget.src = fallbackSrc || '/images/default-poster.png';
      }}
    />
  );
};

export default ImageWithFallback;
